<template lang='pug'>
    div(height='100vh')
        v-img.window-display-image(:src="$route.query.doc_image_url" contain max-height="1280" style="margin-top:50px;")
</template>

<script>
export default {
    name: 'UpPortalDisplayImage',
    data() {
        return{
            
        }
    },
    created() {
        // console.log('收到的資料:', this.$route.query)
    },
    methods: {}
}
</script>

<style scoped lang="css">

</style>