<template lang='pug'>
    div.portal-container.container-fluid.px-0.scrollbar.height_100vh()
        MenuBarPortal(:member='member' @getQuestionDetail='getThread' ref='menuBarPortal')
        div.container-fluid(v-if='is_new_insert')
            v-row(no-gutters='' style='margin-top: 149px;')
                v-col(md='2')
                    v-spacer
                v-col(md='8' pa-0='')
                    .conversation 
                        v-card.text-block.ma-0.pa-0.conversation_title(color='#FCFBF7' flat='' max-height='38') 
                            span {{ $t('MAIN_PORTAL_VIEW.INTRO') }}
                        v-container.d-flex.justify-center.align-center(style='max-height: 172px ; max-width: 800px ; padding: 36px 24px;')
                            ThreadInputTab(:knowledge-list="knowledgeList" @sendmessage='createThread')
                v-col(md='2')
                    v-spacer
        #Portal.portaldetail-main-page.upportal-color.pb-0.position-relative(v-else)
            //- v-row.d-flex.flex-nowrap.align-center.justify-md-space-between.py-7.pb-5(style='border-bottom: 1px solid #E8E8E8; position: fixed; top: 0; right: 0; width: calc(100% - 240px); background: #FCFBF7;  z-index: 20;')
            v-row.d-flex.flex-nowrap.align-center.justify-md-space-between.py-6.pb-4(style='border-bottom: 1px solid #E8E8E8; position: sticky; top: 0; right: 0; width: 100%; background: #FCFBF7;  z-index: 20;')
                v-col.d-flex.align-center.p-0.ml-5(cols='10')
                    v-avatar(color='aliceBlue' style='height: 30px;min-width: 30px;width: 30px;')
                        img(:src='member.pic' height="80")
                    .pl-2.pr-3.user_icon
                        span.name.ellipsis(style='color: #666') {{member.name}}
                //- v-col.ml-3.py-0(cols='1')
                //-     v-btn(v-hide-element='false' :href='"/upgpt/Knowledges/"' width='100%' max-width='105'  height='35' color='#14C6DE' :ripple='false'  depressed  dark style='border-radius:4px')
                //-         v-icon.pr-1.py-2(size='16' color='white') mdi-share-variant
                //-         span.font-weight-bold {{$t("PORTAL.SHARE")}}
            portalLoadingSkeleton(v-if='loading')
            div.container-fluid.p-0(v-else)
                v-row(:style="generating ? 'margin-top: 3%; margin-bottom: 0%;': 'margin-top: 3%; margin-bottom: 5%;'"  v-for="(detail,index) in portalDetails" :key="detail.question_id" :ref="'questionItem-' + index")
                    v-col.UpPortal-height.py-6.pl-6(cols='8')
                        //- v-skeleton-loader(v-if='loading' type="heading")
                        div.primary-input.font-weight-medium() {{detail.question_title}}
                        v-row.mt-5
                            v-col.d-flex.align-center.pa-0(cols='auto')
                                v-img(src="@/assets/images/reference-icon.svg" max-width="22px"  height="22px")
                                span.text-title.pl-2 {{$t("PORTAL.REFERENCE")}}
                            v-spacer
                            v-col.d-flex.pa-0(cols='auto')
                                v-menu(offset-y="" persistent)
                                    template(v-slot:activator="{ on, attrs }")
                                        v-btn.portal-btn-black.pr-0(@click="getQuestionKnowledge(detail.question_id)" v-on="on" v-bind="attrs" text elevation='0' color="transparent")
                                            v-img.pr-2(contain src="@/assets/images/knowledge-lib-icon.svg" max-height="22px" height="22px")
                                            span.ml-1 {{$t("PORTAL.KNOWLEDGE")}}
                                                v-icon.pr-1.ml-1(size='12' color='#444') fa-chevron-down
                                    v-card()
                                        v-list.list-item-scroll(flat)
                                            v-list-item-group( multiple)
                                                v-list-item(v-for="item in questionKnowledgeList" :key="item.id" :ripple="false" @click.stop )
                                                    template(v-slot:default="{ active }")
                                                        v-list-item-action.mr-2
                                                            v-checkbox.ma-0(v-model='detail.knowledges' :value='item' :value-comparator="(a, b) => a.id === b.id" )
                                                        v-list-item-content(style="display: inline;")
                                                            v-avatar(size='20' max-height='20' :color='getColorFromKnowledgeName(item.name)' left class="mr-2" )
                                                                span(style='padding-bottom:2px;') {{item.name.slice(0,1)}}
                                                            span {{item.name}}
                                        v-card-actions.flex-row-reverse
                                            v-btn(text color="#14C6DE" @click="upsertQuestionData(detail)") {{$t("PORTAL.APPLY")}}
                                v-col.d-flex.align-center.py-0()
                                    v-avatar.ml-1(v-for='item in detail.knowledges.slice(0,detail.knowledges.length==10? 10:knowledgeListShowAll)' :key="item.id" :title='item.name'  :color='getColorFromKnowledgeName(item.name)' size='20' rounded="100")
                                        span(style='font-size:14px; padding-bottom:2px;') {{item.name.slice(0,1)}}
                                    v-avatar.ml-1(v-if='detail.knowledges.length > knowledgeDefaultLength+1' color='#a2a2a2' size='20' rounded="100" @click='showAllKnowledge(detail.knowledges)')
                                        span(style='font-size:14px; padding-bottom:2px;') {{ knowledgeListShowAll > knowledgeDefaultLength ? "--" : "+" + String(detail.knowledges.length-knowledgeDefaultLength)}}
                        v-row.mb-3.flex-nowrap.scrollbar-x.pl-1(:class='')
                            v-col.pr-2.pl-0.py-0(cols='3' v-for="(reference) in detail.references" :key="reference.reference_id")
                                v-hover
                                    template(v-slot:default="{ hover }")
                                        v-card.reference-card.mark-card.pt-2.px-2.pb-3(:class="hover ? 'reference-card-hover' : ''" :elevation="hover?3:''" flat  :ripple="false" @click="openDrawer(reference.reference_id)")
                                            v-card-title.flex-nowrap.pa-0.d-flex.align-center.ellipsis
                                                v-avatar.mark.ml-1( size='16' tile)
                                                    span(style='font-size:10px; color:#666; padding-bottom:1px;') {{reference.context_id}}
                                                span.ml-2.mb-1.textx-subtitle-2.font-weight-bold(:title='reference.title?reference.title:reference.filename') {{reference.title?reference.title:reference.filename}}
                                            v-card-text.pt-0.pb-0.pl-7(v-if="reference.display_type=='IMAGE'")
                                                //- template(v-for="referimg in reference.doc_image_urls" )
                                                v-img.source_image(:src='reference.doc_image_urls[0]' width='100%' height='auto' :aspect-ratio="16/9" @error="handleImageError")
                                            v-card-text(v-else)        
                                            v-card-text.ellipsis.pl-0.pb-0(:class="reference.display_type=='IMAGE'?'pt-0':''")
                                                v-avatar.ml-1(:color="getColorFromKnowledgeName(reference.knowledge_lib_name)" size='17' rounded="100"  :title="reference.knowledge_lib_name") 
                                                    span(style='font-size:10px; padding-bottom:2px;' ) {{reference.knowledge_lib_name.slice(0,1)}}
                                                span.pl-2.file(:title="reference.filename") {{reference.filename}}
                                v-chip(flat) {{reference.score_percentage}}%
                            //- v-col.pr-2.pl-0.py-0(cols='3' v-if='detail.references.length>3')
                            //-     v-hover
                            //-         template(v-slot:default="{ hover }")
                            //-             v-card.reference-card.mark-card.pt-3.px-3.pb-2(:class="hover ? 'reference-card-hover' : ''" :elevation="hover?3:''" flat)
                            //-                 v-card-text.d-flex.flex-nowrap.pa-0()
                            //-                     v-icon.mr-1(size='16') mdi-eye
                            //-                     span.mr-1(style='font-size:0.85rem')  View all references
                            //-                     v-icon(size='16') mdi-trending-neutral
                            //-                 v-card-text.pt-0()
                            //-                     v-avatar.mark.ml-1(v-for=" (avatar,i) in detail.references.slice(3)" :key="'avatar-'+i"  size='17' tile)
                            //-                         span(style='font-size:10px; padding-bottom:1px;') {{i+4}}
                            v-col.d-flex.justify-center.px-auto.py-0(cols='12' v-if='detail.references.length==0')
                                v-img(src="@/assets/images/image-No Reference.png" max-width="25%" width="100%")
                        v-row.d-flex.align-center.my-auto.mt-5()
                            v-img(v-if='generating' src="@/assets/images/ans-book.gif" max-width="25px" width="20px" height="20px")
                            v-img(v-else src="@/assets/images/ans-book.svg" max-width="25px" width="20px" height="20px")
                            span.text-title.pl-2 {{$t("PORTAL.ANSWER")}}

                        v-row.answer-line-height()
                            vue-markdown.answer(:source='String(getAnsSupStr(detail.content))' :render="customRender" :data-question-id="detail.question_id")
                        v-divider
                        v-row
                            //- v-col.d-flex.pt-1.pb-0.pl-0(cols='auto')
                            //-     v-btn.mr-1(icon @click="toggleThumbUp()")
                            //-         v-img(v-if="thumb_up" src="@/assets/images/good-active.svg" max-width="21px" width="21px")
                            //-         v-img(v-else src="@/assets/images/good-white.svg" max-width="21px" width="21px")
                            //-     v-btn.mr-1(icon @click="toggleThumbDown()")    
                            //-         v-img(v-if="thumb_down" src="@/assets/images/bad-active.svg" max-width="21px" width="21px")
                            //-         v-img(v-else src="@/assets/images/bad-white.svg" max-width="21px" width="21px")
                            v-spacer
                            v-col.d-flex.pt-1.pb-0.pr-0.pl-0(cols='auto')
                                v-btn.mr-1(icon @click="toggleThumbUp()")
                                    v-img(v-if="thumb_up" src="@/assets/images/good-active.svg" max-width="21px" width="21px")
                                    v-img(v-else src="@/assets/images/good-white.svg" max-width="21px" width="21px")
                                v-btn.mr-1(icon @click="toggleThumbDown()")    
                                    v-img(v-if="thumb_down" src="@/assets/images/bad-active.svg" max-width="21px" width="21px")
                                    v-img(v-else src="@/assets/images/bad-white.svg" max-width="21px" width="21px")    
                                //- v-btn.ml-1(icon)
                                //-     v-icon.pr-2.py-2(size='22') mdi-share-variant
                                v-btn.ml-1(icon @click='copyAnswer(detail.content)')    
                                    v-img(v-if='!copied' src="@/assets/images/copy.svg" max-width="21px" width="21px")
                                    v-img(v-else src="@/assets/images/copied.svg" max-width="21px" width="21px")
                                //- v-btn.ml-1(icon)    
                                //-     v-img(src="@/assets/images/ai-write.svg" max-width="21px" width="21px")
                            
                        v-row.d-flex.align-center.mt-7
                            v-img(src="@/assets/images/relater-ans-icon.svg" max-width="22px"  height="22px")
                            span.text-title.pl-2 {{$t("PORTAL.Related")}}
                        v-row.flex-nowrap.relative-question(cols='3' v-for="(relatedQuestion, i) in detail.related" :key="'related-questions-'+i" style='border-bottom: 1px solid #E8E8E8; cursor :pointer;' :ref="'relatedQuestion'+i" @click='upsertQuestionData(detail,i)')
                            v-col.d-flex.align-center.pl-2.related-hover(cols='11') {{relatedQuestion}}
                            v-spacer
                            v-col.d-flex.py-0(cols='auto')
                                v-icon.pr-2.py-2(size='25' color="#14C6DE") mdi-plus
                        v-row(style='height:52%;')
                    //- right-image
                    v-col.pt-5(cols='4')
                        v-row.d-flex.align-center.my-auto
                            v-img(src="@/assets/images/related-images-icon.svg" max-width="22px" width="22px" )
                            span.text-title.pl-2 {{$t("PORTAL.RelatedImages")}}
                        v-row(v-if="detail.related_image.length>0")
                            v-col.pl-0.pr-2.pt-0.pb-2(v-for="(img,index) in filteredImages(detail)" :key="index" :cols="index==0 ? 12:6")
                                v-card.related-img-card(elevation='0')
                                    v-img(:src="img.doc_image_url" :aspect-ratio="16/9" error @click='openWindowDisplayImage(img)' style="cursor :pointer;")
                                    v-card-text.ellipsis.related-img-overlay
                                        v-chip.related-img-label.mr-1(label) {{img.context_id}}
                                        span.text-white(v-if="img.page_num.length>0") Page {{img.page_num.length>0?img.page_num[0]:0}},
                                        span.pl-1.text-white(v-if="img.image_num!=''") Image {{img.image_num==''?0:img.image_num}}
                        v-row(v-else)
                            v-col.d-flex.pl-0.pr-2.pt-0.pb-2.d(cols='12')
                                v-img(src="@/assets/images/image-no-related-image.png" contain :aspect-ratio="16/9" error width='50%' height='50%')

                        v-row.mt-1()
                            v-col.py-0.d-flex
                                v-btn.portal-btn-black.px-0(v-if='detail.related_image.length>3' @click='viewLessRelatedImage()' text elevation='0' color="transparent")
                                    v-img(src="@/assets/images/related-img-btn.svg" max-width="20px" width="20px")
                                    span.ml-1(v-if="showAllImage") {{$t("PORTAL.ViewMore")}}
                                        v-icon.ml-1(size='12' color='#444') fa-chevron-up
                                    span.ml-1(v-else) {{$t("PORTAL.ViewLess")}} 
                                        v-icon.ml-1(size='12') fa-chevron-down
            detailThreadInput.detailThreadInput(:knowledge-list="knowledgeList" :generating="generating" @queryQuestion='upsertQuestionData')

            v-navigation-drawer.potaldrawer(v-model='drawer' absolute bottom temporary right overlay-opacity="0" width="29%")
                portal-drawer-dialog(@emitPortalDrawer="onEmitPortalDrawer" :selected-reference='selectedReference')
            v-dialog(v-model='contentDialog' width='250' content-class='drawerDialog' hide-overlay=true open-delay=3 )
                portal-content-dialog(:contentMessage='contentMessage' :currentReference='currentReference' :dialogKey='dialogKey' :key="dialogKey" :sourceType="sourceType")
            v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
                error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')

</template>

<script>
// import {inject} from "vue";
import i18n from '/common/plugins/vue-i18n.js';
import VueMarkdown from 'vue-markdown-v2';
import knowledge_color_group from "@/array/the_knowledgeColorGroup";
import MenuBarPortal from "@/layout/MenuBarPortal.vue";
import ThreadInputTab from "@/components/Portal/ThreadInputTab.vue";
import detailThreadInput from "@/components/Portal/detailThreadInput.vue";
import portalLoadingSkeleton from "@/components/Portal/portalLoadingSkeleton.vue";
import portalDrawerDialog from "@/components/Portal/portaldrawerDialog";
import portalContentDialog from "@/components/Portal/portalContentDialog";
import errorDialog from '@/components/Dialog/errorDialog';
import UpGPTPortalDataServices from "../services/UpGPTPortalDataServices"
import KnowledgeLibraryService from "@/services/KnowledgeLibraryService";
export default {
    name: 'UpPortalDetail',
    props: {
        member: {
            type: Object
        }
    },
    components: {
        MenuBarPortal,
        ThreadInputTab,
        detailThreadInput,
        portalLoadingSkeleton,
        portalDrawerDialog,
        portalContentDialog,
        errorDialog,
        VueMarkdown,
    },
    data() {
        return{
            loading:false,
            isAnswerLoading:false,
            generating:false,
            is_new_insert:true,
            searchQuery: "", // 綁定搜尋輸入
            results: [], // 存儲搜尋結果
            new_question: true,
            // reaponse.data.questions
            user_iput_question: '',
            portalDetails:[],
            portalDetailsMap:{},
            knowledgeList:[],
            allKnowledgeColor:[],
            questionKnowledgeList:[],
            knowledge_color_group:knowledge_color_group,
            selectedKnowledgeLibs:[],
            knowledgeListShowAll:9,
            knowledgeDefaultLength:9,
            referencesData:[],
            relatedImages:[],
            selectedReference:{},
            showAllImage: true,
            thumb_up:false,
            thumb_down:false,
            drawer:false,
            copied:false,
            errorDialog: false,
            errorMessage: '',
            current_thread_id:'',
            contentMessage:'',
            currentReference:{},
            contentDialog: false,
            hoverTimeout:'',
            referenceDisplayNum:3,
            dialogKey:0,
            current_cut_content:''
        };
    },
    async created() {
        await this.defaultColorKnowledgeList();
        await this.getKnowledgeList();
    },
    mounted(){
        const observer = new MutationObserver(() => {
            this.bindingEvent();
        });
        observer.observe(document.body, {
            childList: true,
            subtree: true,
        });
        
    },
    methods: {
        // add 回傳{}
        async createThread(data) {
            this.loading = true;
            this.generating = true;
            this.is_new_insert = false;
            this.user_iput_question = data.text;
            this.selectedKnowledgeLibs = data.category;
    
            // 使用 response_createThread.data.thread_id 來獲取 thread_id
            let data_createThread = {"question": data.text};
            let response_createThread = await UpGPTPortalDataServices.createThread(data_createThread);
            this.current_thread_id = response_createThread.data.thread_id

            let upsertdate = {
                "thread_id":this.current_thread_id,
                "question_id": '',
                "question":data.text,
                "knowledges":data.category
            }
            await this.getKnowledgeList();
            // ========================= Streaming =========================
            // Call Streaming API
            let PUBLIC_URL = process.env.VUE_APP_API_BASE_URL;
            let BASE_URL = PUBLIC_URL + '/tenant';
            let stream_res = await fetch(`${BASE_URL}/${window.localStorage.getItem('tenant')}/api/upgpt_portal/thread/${upsertdate.thread_id}/`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json'
                }, 
                body: JSON.stringify(upsertdate)
            });

            // 處理接到的值
            let reader = stream_res.body.getReader();
            let decoder = new TextDecoder('utf-8');

            // 將接到的資料依序顯示
            let finish = false;
            let text_buffer = "";
            while (!finish) {
                // console.log("in")
                this.loading = false;
                // 將拿到的資訊Uint8Array轉譯成文字str (後端回傳JSON String)，再轉回Json格式
                let {done,value} = await reader.read();
                let chunkText = decoder.decode(value);
                chunkText = this.correctJsonFormat(chunkText)
                console.log("Text: ", chunkText)
                let chunkdata = await JSON.parse(chunkText);
                // console.log("Json: ", chunkdata)
                for (let chunk of chunkdata) {
                    // 每次收到資訊，且不是結束，先依照當前資訊更新內容
                    if(chunk['type'] != 'final'){
                        if (chunk['content'].includes('":"') || chunk['content'].includes('","') || chunk['content'].includes('related') || chunk['content'].includes('_questions'))
                            text_buffer += ""
                        else
                            text_buffer += chunk['content']
                        chunk['content'] = text_buffer
                    }
                    // 開始處理每次的回覆
                    let array = []
                    chunk.related_image = await chunk.related_image.flat();
                    array.push(chunk)
                    this.portalDetails = array
                    // 如果收到最後一個chunk就結束迴圈
                    if(chunk['type'] == 'final'){
                        finish = true; // 結束迴圈標記
                        await this.$refs.menuBarPortal.getThreadListData();
                        this.generating = false;
                    }
                }
                if(done){
                    break;
                }
            }
            //===============================================================
            // await UpGPTPortalDataServices.upsertQuestion(upsertdate).then(response=>{
            //     let array = []
            //     let upsertQuestionResponse = JSON.parse(JSON.stringify(response.data));
            //     upsertQuestionResponse.related_image = upsertQuestionResponse.related_image.flat();
            //     array.push(upsertQuestionResponse)
            //     this.portalDetails = array
            // }).catch(response => {
            //     this.showErrorDialog(response);
            //     // 錯誤的時候
            //     this.is_new_insert = true;
            //     this.current_thread_id = '';
            //     this.portalDetails = [];
            //     this.selectedKnowledgeLibs = [];
            // })
            // .finally(()=>{
            //     this.loading = false;
            //     this.$refs.menuBarPortal.getThreadListData();
            // })
        },
        async getThread(type,data){
            if(type == 'insert'){
                this.is_new_insert = true;
                this.current_thread_id = '';
                this.portalDetails = [];
                this.selectedKnowledgeLibs = [];
            }else 
            if(type == 'list'){
                if(!this.generating){
                    this.loading = true;
                    this.is_new_insert = false;
                    const [{ id }] = data;
                    this.current_thread_id = id;
                    let qs_thread_id = {
                        thread_id: id,
                    }
                    await UpGPTPortalDataServices.getQuestion(qs_thread_id).then(response=>{
                        let data = response.data
                        // console.log('get data',data)
                        data.forEach(item => {
                            item.related_image = item.related_image.flat();
                        });
                        this.portalDetails = data
    
                    }).catch(response => {
                        if(response == 'Error: Request failed with status code 400' ||response == 'Error: Request failed with status code 500'){
                            let msg = i18n.t('PORTAL.ERROR_1');
                            this.showErrorDialog(msg);
                        }
                        this.showErrorDialog(response);
       
                    }).finally(()=>{
                        this.loading = false;
                    })
                }
            }
        },
        async upsertQuestionData(data,index){
            this.generating = true;

            data.knowledges.forEach(item=>{
                delete item.color;
            })
            // have index parameter is query related Question else continue query or change knowledge
            let upsertdate;
            if(Number.isFinite(index)){
                upsertdate = {
                    "thread_id":this.current_thread_id,
                    "question_id": '',
                    "question":data.related[index],
                    "knowledges":data.knowledges
                }
            }else{
                upsertdate = {
                    "thread_id":this.current_thread_id,
                    "question_id": data.question_id ? data.question_id :'',
                    "question":data.question_title,
                    "knowledges":data.knowledges
                }
            }
            await this.getKnowledgeList();
            // ========================= Scroll down =========================
            this.$nextTick(() => {
                // let lastIndex,lastItem,element;
                setTimeout(() => {
                    // lastIndex = this.portalDetails.length - 1;
                    // // console.log('lastIndex',lastIndex)
                    // lastItem = this.$refs['questionItem-' + lastIndex];
                    // // console.log(lastItem)
                    // element = Array.isArray(lastItem) ? lastItem[0] : lastItem;
                    // // console.log('element', element)
                    const scrollContainer = document.querySelector('.portal-container');
                    if (scrollContainer) {
                        // const offset = element.offsetTop+700;
                        scrollContainer.scrollTo({
                        top: scrollContainer.scrollHeight+100,
                        behavior: 'smooth'
                        });

                        // Debug 用
                        // console.log('滾動容器：', scrollContainer);
                        // console.log('元素 offsetTop：', offset);
                    }
                }, 1000);
                // again
                const scrollContainerB = document.querySelector('.portal-container');
                scrollContainerB.scrollTo({
                    top: scrollContainerB.scrollHeight,
                    behavior: 'smooth'
                });
            })
            // ========================= Streaming =========================
            // Call Streaming API
            let PUBLIC_URL = process.env.VUE_APP_API_BASE_URL;
            let BASE_URL = PUBLIC_URL + '/tenant';
            let stream_res = await fetch(`${BASE_URL}/${window.localStorage.getItem('tenant')}/api/upgpt_portal/thread/${upsertdate.thread_id}/`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json'
                }, 
                body: JSON.stringify(upsertdate)
            });
            // 處理接到的值
            let reader = stream_res.body.getReader();
            let decoder = new TextDecoder('utf-8');

            // 將接到的資料依序顯示
            let finish = false;
            let text_buffer = "";
            let portalDetailsMap = new Map(
                this.portalDetails.map((item, index) => [item.question_id, index])
            );
            while (!finish) {
                // console.log("in")
                // 將拿到的資訊Uint8Array轉譯成文字str (後端回傳JSON String)，再轉回Json格式
                let {done,value} = await reader.read();
                let chunkText = decoder.decode(value);
                chunkText = this.correctJsonFormat(chunkText)
                console.log("Text: ", chunkText)
                let chunkdata =  JSON.parse(chunkText);

                for (let chunk of chunkdata) {
                    // 每次收到資訊，且不是結束，先依照當前資訊更新內容
                    if(chunk['type'] != 'final'){
                        if (chunk['content'].includes('":"') || chunk['content'].includes('","') || chunk['content'].includes('related') || chunk['content'].includes('_questions'))
                            text_buffer += ""
                        else
                            text_buffer += chunk['content']
                        chunk['content'] = text_buffer
                    }
                    // 開始處理每次的回覆
                    chunk.related_image =  chunk.related_image.flat();
                    // change knowledge to filter the question
                    let editIndex = this.portalDetails.findIndex(item => item.question_id == chunk.question_id);
                    // let editIndex = portalDetailsMap.get(chunk.question_id);
                    if(editIndex != -1 ){
                       this.$set(this.portalDetails,editIndex,chunk)
                    }else{
                        this.portalDetails = this.portalDetails.concat(chunk);
                        portalDetailsMap.set(chunk.question_id, this.portalDetails.length -1);
                    }
                    // // 將畫面移至最新的位置
                    // this.$nextTick(() => {
                    //     let lastIndex,lastItem,element;
                    //     setTimeout(() => {
                    //         lastIndex = this.portalDetails.length - 1;
                    //         lastItem = this.$refs['questionItem-' + lastIndex];

                    //         element = Array.isArray(lastItem) ? lastItem[0] : lastItem;
                    //         if (element) {
                    //             element.scrollIntoView({ behavior: 'smooth' });
                    //         }
                    //     }, 50);
                    // })
                    // 如果收到最後一個chunk就結束迴圈
                    if(chunk['type'] == 'final'){
                        finish = true; // 結束迴圈標記
                        this.generating = false;
                    }
                }
                if(done){
                    break;
                }
            }  
        },
        async getKnowledgeList() {
            await KnowledgeLibraryService.list().then((response) => {
                let allKnowledge = response.data.filter(el =>el.active==true && el.linked_bots_and_knowledge_set.some(bot => bot.ai_bot_id.bot_name === "UpGPT Portal Agent")).map(el=>({id: el.id,name: el.name}))
                this.knowledgeList = this.addColorToKnowledge(allKnowledge)
            })
        },
        async defaultColorKnowledgeList() {
            await KnowledgeLibraryService.select_list().then((response) => {
                this.allKnowledgeColor = response.data.map((item, index) => {
                    item['color'] = knowledge_color_group[index]?.color || 'aliceBlue';
                    return item;
                });
            });
        },
        addColorToKnowledge(knowledgeList){
            return knowledgeList.map((item)=>{
                if(item['color'] == undefined){
                    let matched = this.allKnowledgeColor.find(colorObj=>colorObj.name == item.name)
                    item['color'] = matched?.color || 'aliceBlue';
                    return item;
                }
            })
        },
        getColorFromKnowledgeName(knowledge_name){
            let color_data = this.allKnowledgeColor.find(item=>item.name == knowledge_name)
            return color_data ? color_data.color : 'aliceBlue';
        },
        toggleThumbUp(){
            this.thumb_up = !this.thumb_up;
            this.thumb_up && (this.thumb_down = false)
        },
        toggleThumbDown(){
            this.thumb_down = !this.thumb_down;
            this.thumb_down && (this.thumb_up = false)
        },
        openDrawer(id){
            let targetReference = '';
            for(let item of this.portalDetails){
                targetReference = item.references.find(reference=> reference.reference_id == id)
                if (targetReference) break;
            }
            this.selectedReference = targetReference
            this.drawer = true;
        },
        onEmitPortalDrawer(){
            this.drawer = false;
        },
        getAnsSupStr(str){
            str = String(str).replaceAll(/\[([^\]]+)\]/g,'<sup>$1</sup>')
            return str
        },
        copyAnswer(text){
            text = text.replaceAll(/\[([^\]]+)\]/g,'$1').replace(/\*\*(.*?)\*\*/g, '$1')
            const clipboardData =
            event.clipboardData ||
            window.clipboardData ||
            event.originalEvent?.clipboardData ||
            navigator.clipboard;
            clipboardData.writeText(text)
            if(this.copied == false){
                this.copied = true;
                setTimeout(()=>{
                    this.copied = false;
                },1000)
            }
        },
        customRender(md) {
            md.renderer.rules.strong = (tokens, idx) => {
                const content = tokens[idx + 1].content;
                return `<strong class="clickable" data-content="${content}">${content}</strong>`;
            };
        },
        bindingEvent(){
            this.$nextTick(()=>{
                const supTags = document.querySelectorAll('sup');
                const strongTags = document.querySelectorAll('strong');
                supTags.forEach((tag)=>{
                    tag.addEventListener('mouseover',this.mouseOver);
                    tag.addEventListener('mouseleave', this.hideContentCard);
                    tag.addEventListener('click',this.onClickShowReference);
                    tag.setAttribute('data-bound', 'true');
                })
                strongTags.forEach((tag)=>{
                    tag.addEventListener('click',this.onClickFollowAction);
                })
            })
        },
        mouseOver(e){
            if (this.hoverTimeout) {
                clearTimeout(this.hoverTimeout);
            }
            let mouseX = e.clientX;
            let mouseY = e.clientY;
            let innerContent = e.target.innerText;
            let supEl = e.target;
            let questionEl = supEl.closest('[data-question-id]');
            let questionId;
            if(questionEl){
                questionId = questionEl.getAttribute('data-question-id')
            }
            this.hoverTimeout = setTimeout(()=>{
                this.showContentCard(innerContent,questionId,mouseX,mouseY)
            },700)
        },
        showContentCard(keyword,id,mouseX,mouseY){
            // this.contentMessage = '';
            let question = this.portalDetails.find(item=>item.question_id==id)
            if(question){
                this.contentDialog = true;

                this.$nextTick(() => {
                    let dialogEl = document.querySelector('.v-dialog__content')
                    if (dialogEl) {
                        dialogEl.style.display = 'unset'
                        dialogEl.style.top = `${mouseY}px`
                        dialogEl.style.left = `${mouseX-148}px`
                    }
                    let reference = question.references.find(item => item.context_id == keyword);
                    this.currentReference = reference;
                    this.current_cut_content = this.currentReference.cut_content
                    this.contentMessage = this.currentReference.cut_content
                    this.sourceType= this.currentReference.source_type
                    this.dialogKey++;
                })
            }
        },
        hideContentCard(){
            if (this.hoverTimeout) {
                clearTimeout(this.hoverTimeout);
                this.hoverTimeout = null;
            }
            this.contentDialog = false;
        },
        async onClickFollowAction(e){
            if(!this.generating){
                this.generating = true;
                await this.getKnowledgeList();
                let innerContent = e.target.innerText;
                let strongEl = e.target;
                let questionEl = strongEl.closest('[data-question-id]');
                let questionId;
                if(questionEl){
                    questionId = questionEl.getAttribute('data-question-id')
                    let knowledges =  this.findKnowledge(questionId)
                    this.is_new_insert = false;
                    let upsertdate = {
                        "thread_id":this.current_thread_id,
                        "question_id": '',
                        "question": innerContent,
                        "knowledges":knowledges
                    }
                    // ========================= Scroll down 將畫面移至最新的位置  =========================
                    this.$nextTick(() => {
                        setTimeout(() => {
                            const scrollContainer = document.querySelector('.portal-container');
                            if (scrollContainer) {
                                scrollContainer.scrollTo({
                                top: scrollContainer.scrollHeight,
                                behavior: 'smooth'
                                });
                            }
                        }, 1000);
                            // again
                            const scrollContainerB = document.querySelector('.portal-container');
                            scrollContainerB.scrollTo({
                                top: scrollContainerB.scrollHeight,
                                behavior: 'smooth'
                            });
                    })
                    // ========================= Streaming =========================
                    // Call Streaming API
                    let PUBLIC_URL = process.env.VUE_APP_API_BASE_URL;
                    let BASE_URL = PUBLIC_URL + '/tenant';
                    let stream_res = await fetch(`${BASE_URL}/${window.localStorage.getItem('tenant')}/api/upgpt_portal/thread/${upsertdate.thread_id}/`,
                    {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${window.localStorage.getItem('access_token')}`,
                            'Content-Type': 'application/json'
                        }, 
                        body: JSON.stringify(upsertdate)
                    });

                    // 處理接到的值
                    let reader = stream_res.body.getReader();
                    let decoder = new TextDecoder('utf-8');

                    // 將接到的資料依序顯示
                    let finish = false;
                    let text_buffer = "";
                    while (!finish) {
                        // console.log("in")
                        // 將拿到的資訊Uint8Array轉譯成文字str (後端回傳JSON String)，再轉回Json格式
                        let {done,value} = await reader.read();
                        let chunkText = decoder.decode(value);
                        chunkText = this.correctJsonFormat(chunkText)
                        console.log("Text: ", chunkText)
                        let chunkdata = await JSON.parse(chunkText);
                        // console.log("Json: ", chunkdata)
                        for (let chunk of chunkdata) {
                            // console.log("Chunk: ", chunk)
                            // 每次收到資訊，且不是結束，先依照當前資訊更新內容
                            if(chunk['type'] != 'final'){
                                if (chunk['content'].includes('":"') || chunk['content'].includes('","') || chunk['content'].includes('related') || chunk['content'].includes('_questions'))
                                    text_buffer += ""
                                else
                                    text_buffer += chunk['content']
                                chunk['content'] = text_buffer
                            }
                            // 開始處理每次的回覆
                            chunk.related_image = await chunk.related_image.flat();
                            // change knowledge to filter the question
                            let editIndex = this.portalDetails.findIndex(item => item.question_id == chunk.question_id);
                            if(editIndex != -1){
                                await this.$set(this.portalDetails,editIndex,chunk)
                            }else{
                                this.portalDetails = this.portalDetails.concat(chunk);
                            }
                            this.relatedImages = await chunk.related_image.flat();
                 
                            // 如果收到最後一個chunk就結束迴圈
                            if(chunk['type'] == 'final'){
                                finish = true; // 結束迴圈標記
                                this.generating = false;
                            }
                        }
                        if(done){
                            break;
                        }
                    }
                    //===============================================================
                    // await UpGPTPortalDataServices.upsertQuestion(upsertdate).then(response=>{
                    //     let updateData = JSON.parse(JSON.stringify(response.data));
                    //     updateData.related_image = updateData.related_image.flat();
                    //     this.portalDetails = this.portalDetails.concat(updateData);
                    //     this.relatedImages = updateData.related_image.flat();
                    // })
                    // .catch(response => {this.showErrorDialog(response);})
                    // .finally(()=>{
                    //     this.loading = false;
                    //     this.generating = false;
                    // })
                }
            }
        },
        onClickShowReference(e){
            let innerContent = e.target.innerText;
            let supEl = e.target;
            let questionEl = supEl.closest('[data-question-id]');
            let questionId = questionEl.getAttribute('data-question-id')
            let question = this.portalDetails.find(item=>item.question_id==questionId)
            if(question){
                let reference = question.references.find(item => item.context_id == innerContent);
                this.openDrawer(reference.reference_id);
            }
        },
        findKnowledge(id){
                let question = this.portalDetails.find(item=>item.question_id==id)
                let knowledge = question?.knowledges??[];
            return knowledge
        },
        viewLessRelatedImage(){
            this.showAllImage = !this.showAllImage
        },
        filteredImages(data){
            let relatedImage = data.related_image
            return this.showAllImage ? relatedImage.slice(0,3) : relatedImage
        },
        showAllKnowledge(knowledge){
            if(this.knowledgeListShowAll>this.knowledgeDefaultLength){
                this.knowledgeListShowAll = this.knowledgeDefaultLength;
            }else{
                this.knowledgeListShowAll = knowledge.length;
            }
        },
        showErrorDialog(message) {
            this.errorDialog = true;
            this.errorMessage = message;
        },
        onEmitErrorDialog() {
            this.errorDialog = false;
        },
        handleImageError(e){
            e.target.src = 'https://picsum.photos/id/13/500/300'
        },
        async getQuestionKnowledge(id){
            let targetKnowledge = this.portalDetails.find(item=>item.question_id==id)
            let combineList = this.knowledgeList.concat(targetKnowledge.knowledges)
            const seen = new Set();
            const filteredList = [];
            for (const item of combineList) {
                if (!seen.has(item.id)) {
                seen.add(item.id);
                filteredList.push(item);
                }
                // 如果已經出現過，就不加入 filteredList（等於刪除第二次之後的）
            }
            this.questionKnowledgeList = filteredList
            // console.log('t',this.questionKnowledgeList)
        },
        correctJsonFormat(json_text){
            return `[${json_text.replace(/}\{/g, '},{')}]`;
        },
        openWindowDisplayImage(img){
            let queryString = {
                doc_image_url:img.doc_image_url,
            }
            let routeUrl = this.$router.resolve(
                { 
                    path: '/image_url',
                    query:queryString
                }
            )
            window.open(routeUrl.href, '_blank')
        }

    },
    
    computed: {

    },
    watch:{
        "drawer": function(){
            this.bindingEvent();
        },
    }
};
</script>

<style scoped lang="css">
    .container-fluid {
        width: 100%;
    }
    /* .dialog-scroll{
        overflow-y: auto !important;
        max-height: 250px;
    } */
</style>